import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="Route Timetable" mdxType="Seo" />
    <h1>{`Route Timetable`}</h1>
    <p>{`Get route timetable.`}</p>
    <h2 id={dets.routeTimetable.schemaId}>Route Timetable Schema</h2>
    <SchemaTable dets={dets.routeTimetable} mdxType="SchemaTable" />
    <section className="minHeightSection">
      <h2 id={dets.routeTimetable.routes[0].id}>
  {dets.routeTimetable.routes[0].title}
      </h2>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.routeTimetable.routes[0].params} mdxType="ParamsTable" />
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/routetimetable/401/0
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "trips": [
      {
        "trip_id": "3682198.20.10-404-e19-1.1547.O",
        "offset_dets": {
          "first_stop_id": "8460B5243701",
          "first_stop_name": "Eyre Square, stop 524371",
          "last_stop_id": "8460B5255401",
          "last_stop_name": "Westside Centre, stop 525541",
          "first_stop_offset": 14,
          "last_stop_offset": 0
        },
        "stop_times": [
          {
            "arrival_time": "06:15:00",
            "departure_time": "06:15:00",
            "stop_id": "8460B5243701",
            "stop_sequence": 1,
            "shape_dist_traveled": 0,
            "stop_name": "Eyre Square, stop 524371"
          },
          // ... {} ..
          {
            "arrival_time": "06:32:15",
            "departure_time": "06:32:15",
            "stop_id": "8460B5255401",
            "stop_sequence": 20,
            "shape_dist_traveled": 6725.97,
            "stop_name": "Westside Centre, stop 525541"
          }
        ]
      },
      // {} ...
    ]
  }
}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      